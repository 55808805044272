import React, { useState, useContext, useEffect } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MainListItems from "../components/MainListItems";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import axios from "axios";
import Chip from "@mui/material/Chip";
import Cookies from "js-cookie";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import constants from "../constants.json";
import CardContent from "@mui/material/CardContent";


import { PusherContext } from "../Context";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        WEBRTCSRUS EXPERIENCES
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
  const { step, statusState, teamsAnswers, questionSet, color, adminChannels, correctAnswer } =
    useContext(PusherContext);

  const ConnectionStatus = () => {
    switch (statusState) {
      case "initialized":
        return <ErrorOutlineIcon color="secondary" />;
      case "connected":
        return <CheckCircleOutlineIcon color="success" />;
      case "connecting":
        return <WarningAmberIcon color="secondary" />;
      default:
        return null;
    }
  };
  const [people, setPeople] = useState([]);
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState(1);
  const [lockAnswer, setLockAnswer] = useState(step !== "answer");
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const RevealFinalist = () => {
    axios.defaults.withCredentials = true;

    const revealStages = [
      "none",
      "third",
      "second",
      "first",
      "credits",
      "lineup",
    ];
    let newStep;
    if (step === "final") {
      newStep = revealStages[0];
    }

    if (step === "none") {
      newStep = revealStages[1];
    }
    if (step === "third") {
      newStep = revealStages[2];
    }
    if (step === "second") {
      newStep = revealStages[3];
    }
    if (step === "first") {
      newStep = revealStages[4];
    }
    if (step === "credits") {
      newStep = revealStages[0];
    }

    axios.get(
      `${process.env.REACT_APP_SERVER_URL}/trigger-step?step=${newStep}`
    );
  };

  console.log(teamsAnswers)


  useEffect(() => {
    let newAnswers = []
    Object.entries(teamsAnswers).map(([k, members]) => {

      Object.entries(members).map(([memberid, answer]) => {
      
        Object.entries(adminChannels).map((channel) => {
         
          let membersChannel = channel[1].members.members
          let member = membersChannel[memberid]
  
          if(member){
            member.currentAnswer = answer
            newAnswers.push(member)
          }
        
          console.log( membersChannel[memberid])
         
          // console.log(memberdata)
        })
      })
    })


    setPeople(newAnswers)
  }, [teamsAnswers]);
  
  
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              WEBRTCSRUS
            </Typography>

            <IconButton
              aria-label="status"
              sx={{
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            >
              {ConnectionStatus()}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography
                sx={{ fontSize: 50 }}
                color="text.secondary"
                gutterBottom
              >
                Current Step -> {step}
              </Typography>

              {questionSet && (
                <Grid
                  xs={12}
                  style={{ overflow: "scroll", height: "calc(100vh - 100px" }}
                >
                  {constants.questions
                    .filter((questions, index) => {
                     
                      return questions.q >= questionSet.q;
                    })
                    .map((question) => {
                      return (
                        <Box sx={{ minWidth: 275, marginBottom: "15px" }}>
                          <Card
                            variant="outlined"
                            gutterBottom
                            sx={{
                              borderColor:
                                question.question === questionSet.question
                                  ? "#2e7d32"
                                  : "#d32f2f",
                              backgroundColor:
                                question.question === questionSet.question
                                  ? "#38b83e82"
                                  : "#FFFFFF",
                            }}
                          >
                            <CardContent>
                            <Typography
                                sx={{ fontSize: 50 }}
                                color="text.primary"
                                gutterBottom
                              >
                            {question.q === 4 ? 'Final Question in round' : null}
                            {question.q === 8 ? 'Final Question in round' : null}
                            {question.q === 12 ? 'Final Question in round' : null}
                            </Typography>
                              <Typography
                                sx={{ fontSize: 50 }}
                                color="text.secondary"
                                gutterBottom
                              >
                              {question.q} - {question.question}
                         
                              </Typography>
                              {question.answers.map((answer) => (
                                <Typography
                                  sx={{ fontSize: 50 }}
                                  color="text.secondary"
                                  style={{
                                    color:
                                      question.correctAnswer === answer
                                        ? "#2e7d32"
                                        : "#d32f2f",
                                  }}
                                  gutterBottom
                                >
                                  {answer}
                                </Typography>
                              ))}
                            </CardContent>
                          </Card>
                        </Box>
                      );
                    })}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Typography variant="h6" gutterBottom>
                Users
              </Typography>
              { people && people.map((person => {
                 return  <Typography
                 variant="h6"
                    sx={{ fontSize: 25,
                      color: 'white',
                      padding: 1,
                      backgroundColor:
                      person.currentAnswer.answer === correctAnswer
                        ? "#38b83e82"
                        : "#d32f2f",
                      }}
                    
                 gutterBottom>
               {person.name}  | {person.currentAnswer.time}
               
               </Typography>
              }))

              }
            </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
